.cascade_color_box {
  height: 15px;
  width: 35px;
  display: inline-block;
  vertical-align: middle;
}

#root {
  width: 100vw;
}

#map {
  width: 100%;
}
div.graymap [class*="-ground-pane"] > ymaps:first-child {
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 3.5+ */
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);

  filter: gray;
}

.ymap-placemark-balloon {
  max-height: 250px;
  overflow-y: auto;

  list-style: none;
  padding-left: 0px;
}

.ymap-placemark-balloon__row {
  display: block;
}

.ymap-placemark-balloon__row + .ymap-placemark-balloon__row {
  margin-top: 5px;
}

.ymap-placemark-balloon__link {
  cursor: pointer;
  display: inline-block;
    vertical-align: middle;
    max-width: 250px;
}

.ymap-placemark-balloon__link:hover {
  text-decoration: underline;
}
.ymap-placemark-balloon__icon {
  display: inline-block;
    vertical-align: middle;
    max-width: 24px;
}

/* placemark direction */
.placemark-direction {
  position: absolute;
  top: -24px;
  height: 36px;

  transform: rotate(0deg);
  transform-origin: bottom center;
}
/* placemark direction END */

/* PLACEMARK BOUNCING */
.bouncing {
  text-align: center;
}

@keyframes cicles {
  100% {
    transform: scale(10);
    opacity: 0;
  }
}

.placemark-bouncer {
  position: absolute;
  border-radius: 50%;
  width: 24px;
  color: #18D0CE !important;
  height: 24px;
  opacity: 1;
  box-shadow: 0 0 5px;

  animation: cicles 3s infinite;
  -webkit-animation: cicles 3s infinite;
  -moz-animation: cicles 3s infinite;
  -o-animation: cicles 3s infinite;
}
/* PLACEMARK BOUNCING END */

/*LOCKED PLACEMARK*/
.placemark.placemark-locked:after {
  content: 'lock';
  font-family: 'Material Icons';
  font-feature-settings: 'liga';
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  text-transform: none;
  line-height: 1;
  font-size: 12px;
  width: 10px;
  height: 10px;
  display: block;
  margin: -10px 15px 0;
  overflow: hidden;
}

.placemark-locked:after {
  content: 'lock';
  font-family: 'Material Icons';
  font-feature-settings: 'liga';
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  text-transform: none;
  line-height: 1;
  font-size: 12px;
  width: 10px;
  height: 10px;
  display: block;
  margin: -10px 15px 0;
  overflow: hidden;
}
/*LOCKED PLACEMARK END*/

.circle-resizer {
  display: block;
  width: 15px;
  height: 15px;
  background-color: white;
  border: 1px solid black;
  border-radius: 50%;
  cursor: col-resize;
}

.circle-label {
    position: absolute;
    left: 0px;
    right: 0px;
    white-space: nowrap;
    font-weight: bold;
    font-family: Arial;
}
.circle-label__radius {
  text-align: center;
}

/* CONTEXT MENU */
.context-menu {
  position: absolute;
}
.context-menu__list {
  display: block;
  margin: 0px;
  padding: 0px;
  background-color: white;
  border: 1px solid rgb(159, 159, 159);
}
.context-menu__list > li {
  font-family: Roboto,sans-serif;
  font-size: 12px;
  line-height: 13px;
  list-style: none;
  cursor: pointer;

  padding: 5px;

  -webkit-transition: all .25s ease;
     -moz-transition: all .25s ease;
      -ms-transition: all .25s ease;
       -o-transition: all .25s ease;
          transition: all .25s ease;
}
.context-menu__list > li:hover {
  color: black;
  background-color: #e0e0e0;
}
.map-context-menu-li-icon {
    font-size: 12px;
    line-height: 12px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 3px;
}
/* CONTEXT MENU END */



/*CASCADE*/
.cascade_layout_container {
  position: relative;
  font-family: Georgia;
  font-size: 40px;
  text-align: center;
  font-weight: bold;
  /*border: 1px solid black;*/
}

.cascade-point-wrapper {
  position: absolute;
  bottom: 10px;
  left: -30px;
}

.cascade-point {
  font-size: 16px;
  position: relative;
  display: block;
  margin-bottom: 5px;
 /*  position: absolute;
 left: -23px;
 top: -23px;
 width: 46px;
 height: 46px;
 line-height: 46px;
 border: 2px solid #218703;
 background-color: #F8FDF7;
 color: #218703; */

  /* opacity: 0; */
  top: 20px; opacity: 0;
  animation: cascade_point_appearence .5s forwards;
}

@keyframes cascade_point_appearence {
  /* 0% { top: -20px; opacity: 0; } */
  100% { top: 0px; opacity: 1; }
}

.cascade-point_disappeare {
  top: 0px; opacity: 1;
  animation: cascade_point_disappearence .5s forwards;
}

@keyframes cascade_point_disappearence {
  /* 0% { top: -20px; opacity: 0; } */
  100% { top: 20px; opacity: 0; }
}

.cascade-point__layer {
  transform: skew(-43deg, 3deg);
  -webkit-transform: skew(-43deg, 3deg);
  -moz-transform: skew(-43deg, 3deg);
  -o-transform: skew(-43deg, 3deg);
  -ms-transform: skew(-43deg, 3deg);
  background-color: rgba(239, 1, 0, 0.8);
  width: 50px;
  height: 30px;
  border: 1px solid gray;
  border-radius: 5px;
}
.cascade-point__info {
  position: absolute;
  display: inline-block;
  z-index: 1;
  left: 70px;
  top: 0px;
  box-sizing: border-box;
  padding: 3px 5px 3px 5px;
  background-color: white;
  border-radius: 5px;
  border-style: solid;
  border-width: 1.5px;
  white-space: nowrap;
}

.cascade-point__title {
  color: black;
  display: inline-block;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  font-weight: 550;
}
.cascade-point__value {
  color: #757575;
  display: inline-block;

  font-size: 13px;
  font-family: "Roboto", sans-serif;

  font-weight: normal;
}
/*CASCADE END*/

.ymap-ml-logo {
  width: 100px;
  height: 32px;
  display: block;
  background-size: contain;
}

/* BALLOON */
.balloon-content{
  font-size: 14px;
  font-family: Arial;
  line-height: 22px;
}

.placemark-label {
    text-align: center;
    font-size: 12px;
    display: inline-block;
    position: relative;
    max-width: 50px;
    left: -15px;
    top: -5px;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}

#root {
  height: 100%;
}

body {
  min-height: 100%;
  overflow-x: hidden;
  overflow-y: unset;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
}

/* Ant design lib customizations */
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100%;
  height: 35px !important;
  padding: 0 11px;
  border-radius: 6px !important;
  display: flex;
  align-items: center;
}

.ant-btn-primary {
  border-color: #5154F6 !important;
  background-color: #5154F6 !important;
}

.ant-btn-primary:hover, .ant-btn-primary:focus {
  color: #fff;
  border-color: #5154F6 !important;
  background: #5154F6 !important;
}

.ant-picker {
  width: 220px;
  height: 38px;
  border-radius: 5px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #5154F6 !important;
  border-color: #5154F6 !important;
}

.form_item {
  width: 100%;
  padding: 10px 20px;
  margin-bottom: 0;
}

.form_wrapper {
  margin-bottom: 0;
  width: 100%;
  padding: 10px 20px;
}

.ant-select-selection-item {
  line-height: 22px !important;
}

.icon-option, .color-option {
  display: flex;
  align-items: center;
  margin-bottom: -22px;
}

.icon-option i {
  font-family: 'Material Icons';
  margin-right: 10px;
  font-style: normal;
  font-size: 18px;
 }

.color-option div:first-child {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}